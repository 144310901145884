
import { Component, Vue } from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';
import Multiselect from 'vue-multiselect';
import { getNameByEmail } from '@/utils/users';
import {
    getComponent
} from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';

@Component({
  components: {
    DoughnutChart: () => import('@/lib/charts/doughnutChart'),
	Dropdown: () => getComponent('common/Dropdown'),
	Multiselect,
  },
})
export default class WorkflowAutomationDialog extends Vue {

	whenComponentTypeDropdownSelections = []
	toComponentTypeDropdownSelections = []
	statusChangeFromDropdownSelections = []
	dropdownLocation: any[] = []
	selectedTab = "Browse"
	editingIndex: any = null
	editingComponentFieldIndex: any = null
	editingDropdownType = ''
	showingRuleDropdown = ''
	deletingRule = ''
	whenOptions = [{text: 'Status Changes', value: 'Status Changes', disabled: false}, {text: 'Start Date Arrives', value: 'Start Date Arrives', disabled: true}, {text: 'Due Date Arrives', value: 'Due Date Arrives', disabled: true}]
	thenOptions = [{text: 'Duplicate Tasq', value: 'Duplicate Tasq', disabled: false}, {text: 'Send Email', value: 'Send Email', disabled: true}, {text: 'Send Notification', value: 'Send Notification', disabled: true}]
	durationNumberOptions = [
		{text: '1', value: 1}, 
		{text: '2', value: 2}, 
		{text: '3', value: 3}, 
		{text: '4', value: 4}, 
		{text: '5', value: 5},
		{text: '6', value: 6},
		{text: '7', value: 7},
		{text: '8', value: 8},
		{text: '9', value: 9},
		{text: '10', value: 10},
		{text: '11', value: 11},
		{text: '12', value: 12},
		{text: '13', value: 13},
		{text: '14', value: 14},
		{text: '15', value: 15},
		{text: '16', value: 16},
		{text: '17', value: 17},
		{text: '18', value: 18},
		{text: '19', value: 19},
		{text: '20', value: 20},
		{text: '21', value: 21},
		{text: '22', value: 22},
		{text: '23', value: 23},
		{text: '24', value: 24},
		{text: '24', value: 24},
		{text: '25', value: 25},
		{text: '26', value: 26},
		{text: '27', value: 27},
		{text: '28', value: 28},
		{text: '29', value: 29},
		{text: '30', value: 30},
	]
	durationOptions = [
		{text: 'Hours Later', value: 'Hour'}, 
		{text: 'Days Later', value: 'Day'}, 
		{text: 'Weeks Later', value: 'Week'}, 
		{text: 'Months Later', value: 'Month'}, 
	]


	isSavingData = false
	whenComponents: any[] = []
	thenComponents: any[] = []

	fieldDicts = {
		"Status Changes": [
			{
				key: "Status Changes",
				fieldType: "Multiselect",
				title: "From:",
				type: "FROM",
				position: "full",
				default: "Any",
				options: [{text: 'New', value: 'New'}, {text: 'In Progress', value: 'In Progress'}, {text: 'Done', value: 'Done'}],
				response: []
			},{
				key: "Status Changes",
				fieldType: "Multiselect",
				title: "To:",
				type: "TO",
				position: "full",
				default: "Any",
				options: [{text: 'New', value: 'New'}, {text: 'In Progress', value: 'In Progress'}, {text: 'Done', value: 'Done'}],
				response: []
			}
		],
		"Field Receives Input": [
			{
				fieldType: "Dropdown",
				type: "SINGLE_SELECT",
				options: this.columnDropdownOptions,
				response: ''
			}
		],
		"Duplicate Tasq": [
			{	
				key: "Duplicate Tasq",
				fieldType: "Dropdown",
				type: "SINGLE_SELECT",
				title: "When:",
				position: "inline",
				default: "1",
				options: this.durationNumberOptions,
				response: []
			},{
				key: "Duplicate Tasq",
				fieldType: "Dropdown",
				type: "SINGLE_SELECT",
				title: "When:",
				position: "inline",
				default: "Days Later",
				options: this.durationOptions,
				response: []
			}
		]
	}


	getFullNameFromEmail(email) {
		return getNameByEmail(email)
	}

	get columnDropdownOptions() {
		var columns: any[] = []
		// @ts-ignore
		for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
			// @ts-ignore
			for (var z = 0; z < workspaceModule.activeTable.Groups[x].Columns.length; z++) {
				var didAddColumn = false
				for (var y = 0; y < columns.length; y++) {
					// @ts-ignore
					if (columns[y].value == workspaceModule.activeTable.Groups[x].Columns[z].CustomID) {
						didAddColumn = true
					}
				}
				if (!didAddColumn) {
					// @ts-ignore
					columns.push(workspaceModule.activeTable.Groups[x].Columns[z])
				}
			}
		}
		return columns
	}

	
	get rules() {
		if (workspaceModule.activeTable == null) {
			return []
		}
		// @ts-ignore
		return workspaceModule.activeTable.Rules
	}

	created() {
		this.whenComponents.push({
			titleOptions: this.whenOptions,
			selectedTitleOptions: [],
			titleOptionDetails: {}
		})
		this.thenComponents.push({
			titleOptions: this.thenOptions,
			selectedTitleOptions: [],
			titleOptionDetails: {}
		})
	}


	async deleteRule(ruleID) {
		this.deletingRule = ruleID
		await scheduleModule.deleteTableRule({
			rule_id: ruleID
		})
		location.reload()

	}

	getRuleHtml(rule) {

		var when_rule_html = '<p style="float: left; clear: both;">When '
		var then_rule_html = '<p style="float: left; margin-top: 6px; margin-left: 0px; clear: both;">Then '
		var rule_json = rule.RuleJson
		var when_rule = rule_json.when
		var then_rule = rule_json.then

		for (var x = 0; x < when_rule.rule_json.length; x++) {
			if (x > 0) {
				when_rule_html = when_rule_html + " and "
			}
			if ("Status Changes" == when_rule.rule_json[x].type.value) {
				when_rule_html = when_rule_html + ' <span style="font-weight: 500; text-decoration: underline;">' + when_rule.rule_json[x].type.value + "</span> from "
				for (var y = 0; y < when_rule.rule_json[x].details.length; y++) {
					var details_html = ''
					for (var z = 0; z < when_rule.rule_json[x].details[y].response.length; z++) {
						if (z > 0) {
							details_html = details_html + ", "
						}
						details_html = details_html + '<span style="font-weight: 500; text-decoration: underline;">' + when_rule.rule_json[x].details[y].response[z].text + '</span>'
					}
					if (when_rule.rule_json[x].details[y].response.length == 0) {
						when_rule_html = when_rule_html + ' <span style="font-weight: 500; text-decoration: underline;">Any</span> '
					}
					if (y == 0) {
						when_rule_html = when_rule_html + details_html
					} else {
						when_rule_html = when_rule_html + " to " + details_html
					}
				}
			}
		}

		when_rule_html = when_rule_html + '</p>'

		for (var x = 0; x < then_rule.rule_json.length; x++) {
			if (x > 0) {
				then_rule_html = then_rule_html + " and "
			}
			if ("Duplicate Tasq" == then_rule.rule_json[x].type.value) {
				then_rule_html = then_rule_html + '<span style="font-weight: 500; text-decoration: underline;">' + then_rule.rule_json[x].type.value + '</span> '
				for (var y = 0; y < then_rule.rule_json[x].details.length; y++) {
					var details_html = ''
					for (var z = 0; z < then_rule.rule_json[x].details[y].response.length; z++) {
						if (z > 0) {
							details_html = details_html + ", "
						}
						details_html = details_html + '<span style="font-weight: 500; text-decoration: underline;">' + then_rule.rule_json[x].details[y].response[z].text + '</span> '
					}
					if (y == 0) {
						then_rule_html = then_rule_html + details_html
					} else {
						then_rule_html = then_rule_html + details_html
					}
				}
			}
		}
		then_rule_html = then_rule_html + '</p>'
		var next_arrow_html = '<span \
							style="clear: both; color: rgba(255,255,255,0.66); font-size: 36px; margin-top: 0px; float: left;" \
							class="material-icons-outlined" \
							> \
							arrow_downward \
							</span>'
		return when_rule_html + next_arrow_html+ then_rule_html

	}

	showRuleDropdown(CustomID) {
		if (this.showingRuleDropdown == CustomID) {
			this.showingRuleDropdown = ''
		} else {
			this.showingRuleDropdown = CustomID
		}
	}

	didSelectTab(type) {
		if (type == 'Browse') {
			this.selectedTab = "Browse"
		} else if (type == 'Create') {
			this.selectedTab = "Create"
		}
	}

	closeWindow() {
		this.$emit('close-workflow-automation-dialog')
		
	}

	async createAutomationClicked() {
		this.isSavingData = true
		var whenResult: any[] = []
		for (var x = 0; x < this.whenComponents.length; x++) {

			var componentDetails = {
				type: this.whenComponents[x].selectedTitleOptions[0],
				details: this.whenComponents[x].titleOptionDetails
			}
			whenResult.push(componentDetails)
		}


		var thenResult: any[] = []
		for (var x = 0; x < this.thenComponents.length; x++) {

			var componentDetails = {
				type: this.thenComponents[x].selectedTitleOptions[0],
				details: this.thenComponents[x].titleOptionDetails
			}
			thenResult.push(componentDetails)
		}


		var whenJson = {
			// @ts-ignore
			workspace_id: workspaceModule.activeWorkspace.ID,
			// @ts-ignore
			table_id: workspaceModule.activeTable.ID,
			rule_json: whenResult,
		}
		var thenJson = {
			// @ts-ignore
			workspace_id: workspaceModule.activeWorkspace.ID,
			// @ts-ignore
			table_id: workspaceModule.activeTable.ID,
			rule_json: thenResult,
		}

		var rulesJson = {
			when: whenJson,
			then: thenJson
		}


		await scheduleModule.postNewRule({
				// @ts-ignore
			workspace_id: workspaceModule.activeWorkspace.ID,
				// @ts-ignore
			table_id: workspaceModule.activeTable.ID,
			rule_json: JSON.stringify(rulesJson),
		})
		this.isSavingData = false
		location.reload()
	}

	addActionClicked() {
		this.thenComponents.push({
			titleOptions: this.thenOptions,
			selectedTitleOptions: [],
			titleOptionDetails: {}
		})
	}

	addConditionClicked() {
		this.whenComponents.push({
			titleOptions: this.whenOptions,
			selectedTitleOptions: [],
			titleOptionDetails: {}
		})
	}

	get whenComponentTypeDropdownText() {
		if (this.whenComponentTypeDropdownSelections.length > 0) {
			return this.whenComponentTypeDropdownSelections[0]
		}
		return null
	}

	get toComponentTypeDropdownText() {
		if (this.toComponentTypeDropdownSelections.length > 0) {
			return this.toComponentTypeDropdownSelections[0]
		}
		return null	
	}

	get fromStatusChangeArray() {
		var returnArray: any[] = []
		if (this.statusChangeFromDropdownSelections.length == 0) {
			return returnArray
		} else {
			for (var x = 0; x < this.statusChangeFromDropdownSelections.length; x++) {
				returnArray.push(this.statusChangeFromDropdownSelections[x])	
			}
		}
		return returnArray
	}

	didSelectDropdownOption() {
		if (this.editingDropdownType == "Status Changes") {
			// this.whenComponents[this.editingIndex].titleOptionDetails = JSON.parse(JSON.stringify(this.fieldDicts[this.whenComponents[this.editingIndex].selectedTitleOptions[0]]))

			// this.whenComponents.push({
			// 	titleOptions: this.whenOptions,
			// 	selectedTitleOptions: [],
			// 	titleOptionDetails: {}
			// })
		} else if (this.editingDropdownType == "Duplicate Tasq") {
			this.dropdownLocation = []
		} else if (this.editingDropdownType == "WHEN") {
			this.whenComponents[this.editingIndex].titleOptionDetails = JSON.parse(JSON.stringify(this.fieldDicts[this.whenComponents[this.editingIndex].selectedTitleOptions[0].value]))

			this.dropdownLocation = []
		} else if (this.editingDropdownType == "THEN") {

			this.thenComponents[this.editingIndex].titleOptionDetails = JSON.parse(JSON.stringify(this.fieldDicts[this.thenComponents[this.editingIndex].selectedTitleOptions[0].value]))

			this.dropdownLocation = []
		} else {
			this.dropdownLocation = []
		}
		
	}

	hideDropdown() {
		this.dropdownLocation = []
	}



	didSelectDropdown(e, index, type, fieldIndex = null) {
		if (fieldIndex != null) {
			this.editingComponentFieldIndex = fieldIndex
		}
		this.editingIndex = index
		this.editingDropdownType = type
		if (type == 'Status Changes' || type == 'Duplicate Tasq') {
			// console.log(e)
			const rect = e.srcElement.parentElement.getBoundingClientRect()
			this.dropdownLocation = [rect.x, rect.y + 60]

		} else {
			const rect = e.srcElement.getBoundingClientRect()
			this.dropdownLocation = [rect.x - 13, rect.y + 60]
		}

	}

}
